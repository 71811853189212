<template>
  <div class="titlesTop titlesTop">
    <headTitle title="群众爆料" />
    <dynamic sign="all"></dynamic>
    <div class="paiZhao" @click="releaseContents">
      <img src="../../assets/images/write.png" alt />
      <span>发表动态</span>
    </div>
  </div>
</template>

<script>
import Dynamic from "./Dynamic";
// import InteractionApi from "@/api/interaction";

export default {
  components: {
    Dynamic,
  },
  data() {
    return {
      active: 0,
      allList: [],
      ugcLink: "v2/api/app/ad_other//ugc/select/ugcApp",
      bodyObj: {
        page: 1,
        size: 15,
        title: "",
      },
    };
  },
  created() {},
  mounted() {
    // this.getUserMessage();
  },
  methods: {
    // 发布
    releaseContents() {
      if(this.isToken()){
        this.$router.push('/publish');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.c_wra {
  display: flex;
  .c_con {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}
.paiZhao {
  position: fixed;
  width: 25vw;
  height: 40px;
  bottom: 115px;
  right: 0;
  // background: #1377e2;
  background: rgba(19,119,226,0.8);
  z-index: 9;
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  img {
    width: 6vw;
  }
}
</style>