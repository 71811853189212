<template>
  <div class="container">
    <van-pull-refresh
      style="height: calc(100% - 0px); overflow-y: auto"
      v-model="refreshing"
      @refresh="onRefresh"
      class="gundong"
    >
      <!-- <div v-if="isOnLoad">
        <loading></loading>
      </div> -->
      <van-swipe :show-indicators="false" :loop="false" class="myswipe">
        <van-swipe-item
          v-for="(itemOut, indexOut) in Math.ceil(tabData.length / 8)"
          :key="indexOut"
        > 
          <div class="classSonBox">
            <div v-for="(item, index) in tabData.slice(
                indexOut * 8,
                (indexOut + 1) * 8
              )" class="classSon" 
              :key="index + 1"
              @click="enterClass(item)"
            >
              {{item.ugcTypeName}}
            </div>
          </div>
          <!-- <van-grid :gutter="10" :column-num="4" :border="false">
            <van-grid-item
              v-for="(item, index) in tabData.slice(
                indexOut * 8,
                (indexOut + 1) * 8
              )"
              :key="index + 1"
              :text="item.ugcTypeName"
              @click="enterClass(item)"
            />
          </van-grid> -->
        </van-swipe-item>
      </van-swipe>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="暂无更多数据"
        @load="onLoad"
      >
        <list
          v-if="dynamicList && dynamicList.length > 0"
          :listContent="dynamicList"
          ref="mychild"
        ></list>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import List from "./List";
// import Loading from "../Loading";
import InteractionApi from "@/api/interaction";

export default {
  props: ["sign"],
  components: {
    List,
    // Loading,
  },
  data() {
    return {
      tabData: [], // 分类导航数据
      dynamicList: [], // 动态列表
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      page: 0,
      isOnLoad: true,
      ugcTypeId: "",
    };
  },
  mounted() {
    this.getTabData();
  },
  methods: {
    enterClass(val) {
      // 进入分类页之前，先关闭当前播放的视频和音药
      if (this.dynamicList && this.dynamicList.length) {
        this.$refs.mychild.closeAudio();
        this.$refs.mychild.closeVedio();
      }
      let link;
      link = window.location.href.split("#/")[0] + "#/classPage?id=" + val.id;
      if (val.id === "mime") {
        if(this.isToken()){
          window.location.href = link;
        }
      }else{
        window.location.href = link;
      }
    },
    // 获取导航数据
    getTabData() {
      const params = {
        appName: this.appName,
        regionId: "",
      };
      InteractionApi.getClassification(params).then((res) => {
        this.tabData = res.data;
        const myData = {
          id: "mime",
          ugcTypeName: "我的",
        };
        this.tabData.unshift(myData);
      });
    },
    // 查询所有爆料
    queryAllInformation() {
      let params = {
        page: this.page,
        rows: 10,
        sortBy: "update_time",
        desc: true,
        UserId: "",
        appName: this.appName,
        status: 2,
        ugcTypeId: this.ugcTypeId,
      };
      InteractionApi.getAllDynamic(params)
        .then((res) => {
          // console.log(res);
          if (res.code === 10000) {
            // this.isOnLoad = false;
            this.loading = false;
            this.refreshing = false;
            if (res.data.items && res.data.items.length > 0) {
              if (this.page === 1) {
                this.dynamicList = [];
              }
              this.dynamicList = this.dynamicList.concat(res.data.items);
              this.finished = false;
            } else {
              this.finished = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onLoad() {
      this.page += 1;
      this.queryAllInformation();
    },
    onRefresh() {
      this.page = 1;
      if (this.dynamicList && this.dynamicList.length) {
        this.$refs.mychild.closeAudio();
        this.$refs.mychild.closeVedio();
      }
      this.queryAllInformation();
    },
  },
};
</script>
<style>
.van-grid-item__text {
  font-size: 0.3rem;
  /* background: red; */
}
</style>
<style lang="less" scoped>
.classSonBox{
  width: 100%;
  // padding: 0 3vw;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  padding-left: 3vw;
  align-items: center;
  .classSon{
    padding: 5px 0;
    width: 21.25vw;
    background: #f3f7fa;
    border-radius: 3px;
    margin-top: 3vw;
    margin-right: 3vw;
    text-align: center;
  }
}
.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  // min-height: 100vh;
}
</style>